import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import get from 'lodash/get';

import { ImageDropzone } from '../../../components';
import { RestaurantContext } from '../../../context';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 320,
    marginBottom: theme.spacing(5),
  },
  logo: {
    width: '100%',
    height: 110,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F5F6F6',
    borderRadius: 3,
  },
  label: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const UploadLogo = () => {
  const classes = useStyles();
  const { restaurant, loadingRestaurant, updateRestaurant } = useContext(
    RestaurantContext
  );

  const logoUrl = get(restaurant, 'logoUrl');
  const id = get(restaurant, 'id');

  const handleFile = (file) => {
    updateRestaurant({ id, logo: file });
  };

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        <Typography variant="h6">Restaurant Logo</Typography>
        <Typography>min 300x90px</Typography>
      </div>
      <ImageDropzone handleFile={handleFile} imageUrl={logoUrl} />
      {loadingRestaurant && <div>Uploading...</div>}
    </div>
  );
};

export default UploadLogo;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
}));

export default ({ ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} {...rest}>
      <CircularProgress />
      <p>Loading...</p>
    </div>
  );
};

import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import RootRef from '@material-ui/core/RootRef';
import get from 'lodash/get';

import MenuForm from './MenuForm';
import { Spinner } from '../../../components';
import { MenuContext, LocationContext } from '../../../context';

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
});

const useStyles = makeStyles((theme) => ({
  root: {},
  addButton: {
    marginTop: theme.spacing(3),
    borderRadius: 20.5,
  },
}));

export default () => {
  const classes = useStyles();
  const [list, setList] = useState([]);

  const { menus, loading, reorderMenus, createMenu, updateMenu, deleteMenu } = useContext(
    MenuContext
  );

  const { selectedLocation, updateLocationMenu, locations } = useContext(LocationContext);

  useEffect(() => {
    if (menus && menus.length && !loading) {
      setList(
        menus.map((item) => ({
          ...item,
          checked:
            get(selectedLocation, 'menus', [])
              .map((el) => el.id)
              .indexOf(item.id) > -1,
        }))
      );
    }
  }, [menus, loading, selectedLocation, locations]);

  const handleAdd = () => {
    setList((prev) => [
      ...prev,
      {
        id: `item-${prev.length}`,
        name: '',
        pdfUrl: '',
        editMode: true,
      },
    ]);
  };

  const handleFieldChange = (fieldName, index) => (e) => {
    const val = fieldName === 'pdfUrl' ? e.target.files[0] : e.target.value;

    setList((prev) =>
      prev.map((item, idx) => ({
        ...item,
        [fieldName]: index === idx ? val : item[fieldName],
      }))
    );
  };

  const handleSaveForm = (index) => {
    const { id, name, pdfUrl } = list[index];
    if (id.includes('item-')) {
      createMenu({ name, pdf: pdfUrl });
    } else {
      updateMenu({ id, name, pdf: pdfUrl, position: index + 1 });
    }

    setList((prev) =>
      prev.map((item, idx) => ({
        ...item,
        editMode: idx === index ? false : item.editMode,
      }))
    );
  };

  const handleEditForm = (index) => {
    setList((prev) =>
      prev.map((item, idx) => ({
        ...item,
        editMode: idx === index ? true : item.editMode,
      }))
    );
  };

  const handleDelete = (index) => {
    const id = get(list[index], 'id');
    if (!id.includes('item-')) {
      deleteMenu(id);
    }

    setList((prev) => prev.filter((_, idx) => idx !== index));
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    reorderMenus(result.source.index, result.destination.index);
  };

  const handleDeletePdf = (menuId) => {
    updateMenu({ id: menuId, pdf: null });
    setList((prev) =>
      prev.map((item, idx) => ({
        ...item,
        pdfUrl: item.id === menuId ? null : item.pdfUrl,
      }))
    );
  };

  const handleCheck = (e, menuId) => {
    updateLocationMenu(menuId, e.target.checked);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={classes.root}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <RootRef rootRef={provided.innerRef}>
              <>
                <div style={getListStyle(snapshot.isDraggingOver)}>
                  {list.map((item, idx) => (
                    <Draggable key={item.id} draggableId={item.id} index={idx}>
                      {(provided, snapshot) => (
                        <MenuForm
                          ref={provided.innerRef}
                          {...item}
                          index={idx}
                          onEditForm={handleEditForm}
                          onFieldChange={handleFieldChange}
                          onSaveForm={handleSaveForm}
                          onDelete={handleDelete}
                          onCheck={handleCheck}
                          onDeletePdf={handleDeletePdf}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        />
                      )}
                    </Draggable>
                  ))}
                </div>
                {provided.placeholder}
              </>
            </RootRef>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        className={classes.addButton}
        disableElevation
        size="large"
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAdd}
        disabled={!!list.filter((item) => item.editMode).length}
      >
        ADD Menu
      </Button>
    </div>
  );
};

import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Button, Container } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';

import logoTextIcon from '../assets/logo-text-only.svg';

import { AuthContext } from '../context';

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  logo: {
    marginRight: 'auto',
  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: 20,
  },
}));

export default function MuiAppBar() {
  const classes = useStyles();
  const history = useHistory();
  const { logout, authUser } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
    history.push('/');
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Container>
          <Toolbar>
            <img src={logoTextIcon} alt="logo" className={classes.logo} />
            {authUser && (
              <>
                <Button>
                  <EditIcon className={classes.icon} />
                  MENU DETAILS
                </Button>
                <Button onClick={handleLogout}>
                  <SettingsIcon className={classes.icon} />
                  ACCOUNT
                </Button>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RootRef from '@material-ui/core/RootRef';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import get from 'lodash/get';

import LocationForm from './LocationForm';
import { Spinner } from '../../../components';
import { LocationContext } from '../../../context';

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
});

const useStyles = makeStyles((theme) => ({
  root: {},
  addButton: {
    marginTop: theme.spacing(3),
    borderRadius: 20.5,
  },
}));

export default () => {
  const classes = useStyles();
  const [list, setList] = useState([]);

  const {
    loading,
    locations,
    selectedLocation,
    reorderLocations,
    createLocation,
    updateLocation,
    deleteLocation,
    setSelectedLocation,
  } = useContext(LocationContext);

  useEffect(() => {
    if (locations && locations.length && !loading) {
      setList(locations);
      setSelectedLocation(
        locations.find((item) => item.id === selectedLocation?.id)
          ? selectedLocation
          : locations[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, loading]);

  const handleAdd = () => {
    setList((prev) => [
      ...prev,
      {
        id: `item-${prev.length}`,
        name: '',
        // address: '',
        tables: '',
        editMode: true,
      },
    ]);
  };

  const handleFieldChange = (fieldName, index) => (e) => {
    const val = e.target.value;
    setList((prev) =>
      prev.map((item, idx) => ({
        ...item,
        [fieldName]: index === idx ? val : item[fieldName],
      }))
    );
  };

  const handleSaveForm = (index) => {
    const { id, name, tables } = list[index];
    if (id.includes('item-')) {
      createLocation({ name, tables });
    } else {
      updateLocation({ id, name, tables, position: index + 1 });
    }

    setList((prev) =>
      prev.map((item, idx) => ({
        ...item,
        editMode: idx === index ? false : item.editMode,
      }))
    );
  };

  const handleEditForm = (index) => {
    setList((prev) =>
      prev.map((item, idx) => ({
        ...item,
        editMode: idx === index ? true : item.editMode,
      }))
    );
  };

  const handleDelete = (index) => {
    const id = get(list[index], 'id');
    if (!id.includes('item-')) {
      deleteLocation(id);
    }
    setList((prev) => prev.filter((_, idx) => idx !== index));
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    reorderLocations(result.source.index, result.destination.index);
  };

  const handleClick = (locationId) => {
    if (!locationId.includes('item-')) {
      setSelectedLocation(list.find((item) => item.id === locationId));
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={classes.root}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <RootRef rootRef={provided.innerRef}>
              <>
                <div style={getListStyle(snapshot.isDraggingOver)}>
                  {list.map((item, idx) => (
                    <Draggable key={item.id} draggableId={item.id} index={idx}>
                      {(provided, snapshot) => (
                        <LocationForm
                          ref={provided.innerRef}
                          {...item}
                          index={idx}
                          selected={item.id === get(selectedLocation, 'id')}
                          onClick={() => handleClick(item.id)}
                          onEditForm={handleEditForm}
                          onFieldChange={handleFieldChange}
                          onSaveForm={handleSaveForm}
                          onDelete={handleDelete}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        />
                      )}
                    </Draggable>
                  ))}
                </div>
                {provided.placeholder}
              </>
            </RootRef>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        className={classes.addButton}
        disableElevation
        size="large"
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAdd}
        disabled={!!list.filter((item) => item.editMode).length}
      >
        ADD LOCATION
      </Button>
    </div>
  );
};

import { createContext } from 'react';

export default createContext({
  loading: false,
  locations: [],
  selectedLocation: null,
  setSelectedLocation: () => {},
  setLocations: () => {},
  createLocation: () => {},
  updateLocation: () => {},
  deleteLocation: () => {},
  reorderLocations: () => {},
  updateLocationMenu: () => {},
});

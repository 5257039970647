import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import get from 'lodash/get';

import {
  SIGNUP,
  LOGIN,
  CREATE_RESTAURANT,
  UPDATE_RESTAURANT,
  CREATE_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  CREATE_MENU,
  UPDATE_MENU,
  DELETE_MENU,
  UPDATE_LOCATION_MENU,
} from '../graphql/mutations';

import useSnackbar from '../hooks/useSnackbar';
import { setToken, removeToken, reorder } from '../utils';

export default () => {
  const { notify } = useSnackbar();
  const [signUp] = useMutation(SIGNUP);
  const [login] = useMutation(LOGIN);
  const [createRestaurant] = useMutation(CREATE_RESTAURANT);
  const [updateRestaurant] = useMutation(UPDATE_RESTAURANT);
  const [createLocation] = useMutation(CREATE_LOCATION);
  const [updateLocation] = useMutation(UPDATE_LOCATION);
  const [deleteLocation] = useMutation(DELETE_LOCATION);
  const [updateLocationMenu] = useMutation(UPDATE_LOCATION_MENU);
  const [createMenu] = useMutation(CREATE_MENU);
  const [updateMenu] = useMutation(UPDATE_MENU);
  const [deleteMenu] = useMutation(DELETE_MENU);

  const [authUser, setAuthUser] = useState();
  const [loadingUser, setLoadingUser] = useState();

  const [restaurant, setRestaurant] = useState();
  const [loadingRestaurant, setLoadingRestaurant] = useState(false);

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [loadingLocations, setLoadingLocations] = useState(false);

  const [menus, setMenus] = useState([]);
  const [loadingMenus, setLoadingMenus] = useState(false);

  const authContext = {
    loading: loadingUser,
    authUser,
    setAuthUser: (value) => setAuthUser(value),
    login: async (email, password) => {
      setLoadingUser(true);
      try {
        const { data } = await login({
          variables: {
            email,
            password,
          },
        });
        const user = get(data, 'login.user');
        if (!user) {
          throw new Error('User does not exist');
        }
        const token = get(data, 'login.user.token');
        const restaurant = get(data, 'login.user.restaurant');
        const locations = get(data, 'login.user.restaurant.locations');
        const menus = get(data, 'login.user.restaurant.menus');
        setAuthUser(user);
        setToken(token);
        setRestaurant(restaurant);
        setLocations(locations);
        setMenus(menus);
      } catch (err) {
        notify(err.message);
      }
      setLoadingUser(false);
    },
    signup: async (email, password, passwordConfirmation) => {
      setLoadingUser(true);
      try {
        const { data } = await signUp({
          variables: {
            email,
            password,
            passwordConfirmation,
          },
        });
        const user = get(data, 'signUp.user');
        const token = get(data, 'signUp.user.token');
        setAuthUser(user);
        setToken(token);
      } catch (err) {
        notify(err.message);
      }
      setLoadingUser(false);
    },
    logout: () => {
      setAuthUser(null);
      setRestaurant(null);
      setLocations([]);
      setMenus([]);
      setSelectedLocation(null);
      removeToken();
    },
  };

  const restaurantContext = {
    loading: loadingRestaurant,
    restaurant,
    setRestaurant: (value) => setRestaurant(value),
    createRestaurant: async ({
      name,
      domain,
      locationName,
      locationTables,
      locationAddress,
    }) => {
      setLoadingRestaurant(true);
      try {
        const variables = { name };
        Object.assign(
          variables,
          domain && { domain },
          locationName && { locationName },
          locationTables && { locationTables },
          locationAddress && { locationAddress }
        );
        const { data } = await createRestaurant({
          variables,
        });

        const restaurant = get(data, 'createRestaurant.restaurant');
        const locations = get(data, 'createRestaurant.restaurant.locations');
        setRestaurant(restaurant);
        setLocations(locations);
      } catch (err) {
        notify(err.message);
      }
      setLoadingRestaurant(false);
    },
    updateRestaurant: async ({ id, name, domain, logo, resize }) => {
      setLoadingRestaurant(true);
      try {
        const variables = { id, logo };
        Object.assign(
          variables,
          name && { name },
          domain && { domain },
          resize && { resize }
        );
        const { data } = await updateRestaurant({
          variables,
        });

        const restaurant = get(data, 'updateRestaurant.restaurant');
        setRestaurant(restaurant);
      } catch (err) {
        notify(err.message);
      }
      setLoadingRestaurant(false);
    },
  };

  const locationContext = {
    loading: loadingLocations,
    locations,
    selectedLocation,
    setLocations: (value) => setLocations(value),
    createLocation: async ({ name, address, tables }) => {
      setLoadingLocations(true);
      try {
        const { data } = await createLocation({
          variables: {
            name,
            address,
            tables: Number(tables),
          },
        });
        const location = get(data, 'createLocation.location');
        setLocations((prev) => [...prev, location]);
      } catch (err) {
        notify(err.message);
      }
      setLoadingLocations(false);
    },
    updateLocation: async ({ id, name, address, tables, position }) => {
      setLoadingLocations(true);
      try {
        const variables = { id };
        Object.assign(
          variables,
          name && { name },
          address && { address },
          tables && { tables: Number(tables) },
          !isNaN(position) && { position: Number(position) }
        );
        const { data } = await updateLocation({ variables });
        const location = get(data, 'updateLocation.location');
        setLocations(locations.map((item) => (item.id === id ? location : item)));
      } catch (err) {
        notify(err.message);
      }
      setLoadingLocations(false);
    },
    deleteLocation: async (id) => {
      setLoadingLocations(true);
      try {
        await deleteLocation({ variables: { id } });
        setLocations((prev) => [...prev.filter((item) => item.id !== id)]);
      } catch (err) {
        notify(err.message);
      }
      setLoadingLocations(false);
    },
    reorderLocations: async (sourceIndex, destinationIndex) => {
      setLoadingLocations(true);
      try {
        const id = get(locations[sourceIndex], 'id');
        const { data } = await updateLocation({
          variables: {
            id,
            position: destinationIndex + 1,
          },
        });
        const location = get(data, 'updateLocation.location');
        const newItems = reorder(locations, sourceIndex, destinationIndex);
        setLocations(
          newItems.map((item, idx) =>
            item.id === id ? location : { ...item, position: idx + 1 }
          )
        );
      } catch (err) {
        notify(err.message);
      }
      setLoadingLocations(false);
    },
    setSelectedLocation: (location) => {
      setSelectedLocation(location);
    },
    updateLocationMenu: async (menuId, connect) => {
      setLoadingLocations(true);
      try {
        const selectedLocationId = get(selectedLocation, 'id');
        const { data } = await updateLocationMenu({
          variables: {
            menuId,
            locationId: selectedLocationId,
            connect,
          },
        });

        const location = get(data, 'updateLocationMenu.location');
        setLocations(
          locations.map((item) => (item.id === selectedLocationId ? location : item))
        );
        setSelectedLocation(location);
      } catch (err) {
        notify(err.message);
      }
      setLoadingLocations(false);
    },
  };

  const menuContext = {
    loading: loadingMenus,
    menus,
    setMenus: (value) => setMenus(value),
    createMenu: async ({ name, pdf }) => {
      setLoadingMenus(true);
      try {
        const locationId = get(selectedLocation, 'id');
        const variables = { name, locationId };
        Object.assign(variables, pdf && { pdf });
        const { data } = await createMenu({ variables });
        const menu = get(data, 'createMenu.menu');
        setSelectedLocation({
          ...selectedLocation,
          menus: [...selectedLocation.menus, menu],
        });
        setMenus([...menus, menu]);
        setLocations(
          locations.map((item) => ({
            ...item,
            menus: item.id === locationId ? [...item.menus, menu] : item.menus,
          }))
        );
      } catch (err) {
        notify(err.message);
      }
      setLoadingMenus(false);
    },
    updateMenu: async ({ id, locationId, name, position, pdf }) => {
      setLoadingMenus(true);
      try {
        const variables = { id };
        Object.assign(
          variables,
          locationId && { locationId },
          name && { name },
          position && { position },
          typeof pdf !== 'string' && { pdf }
        );
        const { data } = await updateMenu({ variables });
        const menu = get(data, 'updateMenu.menu');
        setMenus((prev) => prev.map((item) => (item.id === id ? menu : item)));
      } catch (err) {
        notify(err.message);
      }
      setLoadingMenus(false);
    },
    deleteMenu: async (id) => {
      setLoadingMenus(true);
      try {
        await deleteMenu({ variables: { id } });
        setMenus(menus.filter((item) => item.id !== id));
        setSelectedLocation({
          ...selectedLocation,
          menus: selectedLocation.menus.filter((item) => item.id !== id),
        });
      } catch (err) {
        notify(err.message);
      }
      setLoadingMenus(false);
    },
    reorderMenus: async (sourceIndex, destinationIndex) => {
      setLoadingMenus(true);
      try {
        const id = get(menus[sourceIndex], 'id');
        const { data } = await updateMenu({
          variables: {
            id,
            position: destinationIndex + 1,
          },
        });
        const menu = get(data, 'updateMenu.menu');
        const newItems = reorder(menus, sourceIndex, destinationIndex);
        setMenus(
          newItems.map((item, idx) =>
            item.id === id ? menu : { ...item, position: idx + 1 }
          )
        );
      } catch (err) {
        notify(err.message);
      }
      setLoadingMenus(false);
    },
  };

  return {
    authContext,
    restaurantContext,
    locationContext,
    menuContext,
  };
};

import React, { forwardRef } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: 'rgba(60, 72, 88, 0.05)',
    padding: '6px',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'transparent',
  },
  iconButton: {
    marginLeft: theme.spacing(2),
  },
  tables: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    marginLeft: theme.spacing(1),
    marginRight: 'auto',
  },
  selected: {
    background: 'rgba(38, 207, 175, 0.1)',
    borderColor: '#26CFAF',
  },
}));

const LocationForm = forwardRef(
  (
    {
      index,
      name,
      location,
      tables,
      selected,
      onFieldChange,
      onSaveForm,
      editMode,
      onEditForm,
      onDelete,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();

    return (
      <>
        <div
          className={cx(classes.root, selected && classes.selected)}
          ref={ref}
          {...rest}
        >
          <DragIndicatorIcon />
          <Typography component="h6" className={classes.name}>
            {editMode && (
              <TextField
                autoFocus
                variant="outlined"
                name="name"
                size="small"
                value={name}
                onChange={onFieldChange('name', index)}
              />
            )}
            {!editMode && <span>{name}</span>}
          </Typography>
          <Typography component="h6" className={classes.tables}>
            {editMode && (
              <TextField
                variant="outlined"
                name="tables"
                size="small"
                value={tables}
                onChange={onFieldChange('tables', index)}
                type="number"
              />
            )}
            {!editMode && <span className={classes.tables}>{tables}</span>}&nbsp;
            <span>Tables</span>
          </Typography>

          <IconButton
            className={classes.iconButton}
            color="primary"
            aria-label={editMode ? 'save' : 'edit'}
            onClick={() => (editMode ? onSaveForm(index) : onEditForm(index))}
          >
            {editMode ? <CheckIcon /> : <EditIcon />}
          </IconButton>
          <IconButton
            className={classes.iconButton}
            color="secondary"
            aria-label="clear"
            onClick={() => onDelete(index)}
          >
            <ClearIcon />
          </IconButton>
        </div>
        <Divider />
      </>
    );
  }
);

export default LocationForm;

import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, IconButton, Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import Divider from '@material-ui/core/Divider';

import { Checkbox } from '../../../components';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: 'rgba(60, 72, 88, 0.05)',
    padding: '6px',
  },
  iconButton: {
    marginLeft: theme.spacing(2),
  },
  name: {
    marginLeft: theme.spacing(1),
    marginRight: 'auto',
  },
  pdfUrl: {
    marginRight: theme.spacing(2),
    textDecoration: 'none',
    fontSize: 14,
  },
  deletePdf: {
    background: 'none',
    marginRight: theme.spacing(1),
  },
}));

const LocationForm = forwardRef(
  (
    {
      index,
      id: menuId,
      name,
      editMode,
      pdfUrl,
      checked,
      onFieldChange,
      onSaveForm,
      onEditForm,
      onDelete,
      onDeletePdf,
      onCheck,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();

    return (
      <>
        <div className={classes.root} ref={ref} {...rest}>
          <DragIndicatorIcon />
          <Typography component="h6" className={classes.name}>
            {editMode && (
              <TextField
                autoFocus
                variant="outlined"
                name="name"
                size="small"
                value={name}
                onChange={onFieldChange('name', index)}
              />
            )}
            {!editMode && <span>{name}</span>}
          </Typography>
          {editMode && !pdfUrl && (
            <Button variant="outlined" component="label">
              UPLOAD PDF MENU
              <input
                type="file"
                accept="application/pdf"
                value={pdfUrl}
                style={{ display: 'none' }}
                onChange={onFieldChange('pdfUrl', index)}
              />
            </Button>
          )}

          {!editMode && pdfUrl && (
            <>
              <IconButton
                size="small"
                className={classes.deletePdf}
                aria-label="delete"
                onClick={() => onDeletePdf(menuId)}
              >
                <DeleteIcon />
              </IconButton>
              <Typography
                className={classes.pdfUrl}
                color="secondary"
                component="a"
                href={pdfUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                View PDF
              </Typography>
            </>
          )}

          {!editMode && (
            <Checkbox checked={checked} onChange={(e) => onCheck(e, menuId)} />
          )}

          <IconButton
            className={classes.iconButton}
            color="primary"
            aria-label={editMode ? 'save' : 'edit'}
            onClick={() => (editMode ? onSaveForm(index) : onEditForm(index))}
          >
            {editMode ? <CheckIcon /> : <EditIcon />}
          </IconButton>
          <IconButton
            className={classes.iconButton}
            color="secondary"
            aria-label="clear"
            onClick={() => onDelete(index)}
          >
            <ClearIcon />
          </IconButton>
        </div>
        <Divider />
      </>
    );
  }
);

export default LocationForm;

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, LinearProgress } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { AuthContext } from '../../context';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    background: 'rgba(38, 207, 175, 0.75)',
    paddingTop: '14%',
  },
  form: {
    margin: 'auto',
    maxWidth: 300,
  },
  continue: {
    borderRadius: 20,
    fontWeight: 500,
    fontSize: 12,
    padding: theme.spacing(1, 6),
  },
}));

export default () => {
  const classes = useStyles();
  const { login, loading } = useContext(AuthContext);

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Email is Required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
          }

          if (!values.password) {
            errors.password = 'Password is required';
          }

          return errors;
        }}
        onSubmit={({ email, password }) => {
          login(email, password);
        }}
      >
        {({ submitForm }) => (
          <Form className={classes.form}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  autoFocus
                  component={TextField}
                  name="email"
                  type="email"
                  label="Email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  component={TextField}
                  type="password"
                  label="Password"
                  name="password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {loading && <LinearProgress />}
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.continue}
                  size="large"
                  disableElevation
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  endIcon={<ChevronRightIcon />}
                  onClick={submitForm}
                >
                  CONTINUE
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Link to="/signup">Signup</Link>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

import { createContext } from 'react';

export default createContext({
  loading: false,
  menus: [],
  setMenus: () => {},
  createMenu: () => {},
  updateMenu: () => {},
  deleteMenu: () => {},
  reorderMenus: () => {},
});

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ApolloProvider } from '@apollo/react-hooks';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import apolloClient from './graphql/apolloClient';

import theme from './theme';
import Routes from './routes';

import {
  AuthContext,
  RestaurantContext,
  LocationContext,
  MenuContext,
  useGlobalContext,
} from './context';

import * as serviceWorker from './serviceWorker';

import './index.css';

const history = createBrowserHistory();

const ContextRoutes = () => {
  const {
    authContext,
    restaurantContext,
    locationContext,
    menuContext,
  } = useGlobalContext();

  return (
    <AuthContext.Provider value={authContext}>
      <RestaurantContext.Provider value={restaurantContext}>
        <LocationContext.Provider value={locationContext}>
          <MenuContext.Provider value={menuContext}>
            <Routes />
          </MenuContext.Provider>
        </LocationContext.Provider>
      </RestaurantContext.Provider>
    </AuthContext.Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={apolloClient}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <CssBaseline />
            <ContextRoutes />
          </SnackbarProvider>
        </ApolloProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#26CFAF',
    },
    secondary: {
      main: '#3C4858',
    },
    success: {
      main: '#4CAF50',
    },
    info: {
      main: '#00BCD4',
    },
    warning: {
      main: '#F44336',
    },
    error: {
      main: '#F4493C',
    },
    background: {
      default: '#fff',
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.14)',
      },
      colorPrimary: {
        backgroundColor: '#F4F4F4',
      },
    },
    MuiButton: {
      root: {
        fontSize: 12,
        lineHeight: '14px',
        borderRadius: 3,
      },
      text: {
        padding: '15px 19px',
      },
      containedPrimary: {
        color: '#fff',
      },
    },
    MuiToolbar: {
      gutters: {
        padding: 0,
        '@media (min-width: 600px)': {
          padding: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        backgroundColor: '#9C9C9C',
        borderRadius: 3,
        padding: 3,
      },
      colorPrimary: {
        color: 'white',
        '&:hover': {
          backgroundColor: '#4CAF50',
          boxShadow:
            '0px 2px 2px rgba(76, 175, 80, 0.5), 0px 3px 1px -2px rgba(76, 175, 80, 0.5), 0px 1px 5px rgba(76, 175, 80, 0.5)',
        },
      },
      colorSecondary: {
        color: 'white',
        '&:hover': {
          backgroundColor: '#F44336',
        },
      },
    },
    MuiInput: {
      root: {
        height: 50,
        background: '#FFF',
      },
    },
    MuiInputBase: {
      input: {
        padding: '6px 10px 7px',
      },
    },
    MuiInputLabel: {
      shrink: {
        fontWeight: 500,
        fontSize: 24,
        lineHeight: '28px',
        color: '#FFF',
        '& + .MuiInput-formControl': {
          marginTop: 30,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: '#FFF',
        },
        '&.Mui-error': {
          color: '#FFF',
        },
      },
    },
    MuiOutlinedInput: {
      inputMarginDense: {
        paddingTop: 6.5,
        paddingBottom: 6.5,
      },
    },
    MuiTab: {
      root: {
        '@media (min-width: 600px)': {
          minWidth: 'fit-content',
        },
      },
    },
  },
});

export default theme;

import React, { useContext, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';

import { GET_ME } from '../graphql/queries';
import { Spinner } from '../components';
import { Topbar } from '../layouts';
import Dashboard from './Dashboard';

import Signup from './Signup';
import Login from './Login';

import CreateRestaurant from './CreateRestaurant';

import { AuthContext, RestaurantContext, LocationContext, MenuContext } from '../context';

const Authenticated = () => {
  const { restaurant } = useContext(RestaurantContext);

  return restaurant ? (
    <Switch>
      <Route path="/dashboard" component={Dashboard} />
      <Redirect to="/dashboard" />
    </Switch>
  ) : (
    <Switch>
      <Route path="/restaurant" component={CreateRestaurant} />
      <Redirect to="/restaurant" />
    </Switch>
  );
};

const NonAuthenticated = () => {
  return (
    <Switch>
      <Route path="/signup" component={Signup} />
      <Route path="/login" component={Login} />
      <Redirect to="/login" />
    </Switch>
  );
};

const Routes = () => {
  const { authUser } = useContext(AuthContext);

  return authUser ? <Authenticated /> : <NonAuthenticated />;
};

export default () => {
  const { setAuthUser } = useContext(AuthContext);
  const { setRestaurant } = useContext(RestaurantContext);
  const { setLocations } = useContext(LocationContext);
  const { setMenus } = useContext(MenuContext);

  const { data, loading } = useQuery(GET_ME);

  useEffect(() => {
    console.log('ME: ', data, loading);
    if (data && data.me && !loading) {
      const user = get(data, 'me');
      const restaurant = get(data, 'me.restaurant');
      const locations = get(data, 'me.restaurant.locations');
      const menus = get(data, 'me.restaurant.menus');

      setAuthUser(user);
      setRestaurant(restaurant);
      setLocations(locations);
      setMenus(menus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Topbar />
      <Routes />
    </>
  );
};

import { createContext } from 'react';

export default createContext({
  loading: false,
  authUser: null,
  setAuthUser: () => {},
  login: () => {},
  signup: () => {},
  logout: () => {},
});

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  loadingText: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    fontSize: 18,
    fontWeight: 'bold',
    alignText: 'center',
  },
}));

export default function Pdf({ source }) {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document
        file={source}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<p className={classes.loadingText}>Loading PDF...</p>}
      >
        {numPages > 0
          ? [...new Array(numPages)].map((item, idx) => (
              <Page height={460} pageNumber={idx + 1} />
            ))
          : null}
      </Document>
    </div>
  );
}

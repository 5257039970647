import gql from 'graphql-tag';

export const GET_ME = gql`
  query GetMe {
    me {
      email
      restaurant {
        id
        name
        logoUrl
        menus {
          id
          name
          pdfUrl
          position
        }
        locations {
          id
          name
          tables
          position
          menus {
            id
            name
            pdfUrl
            position
          }
        }
      }
    }
  }
`;

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles({
  root: {
    padding: 0,
    background: '#FFFFFF',
    border: '1px solid #26CFAF',
    borderRadius: 3,
    width: 30,
    height: 30,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
});

function StyledCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="primary"
      checkedIcon={<CheckIcon />}
      icon={<span />}
      {...props}
    />
  );
}

export default StyledCheckbox;

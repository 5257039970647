import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, LinearProgress, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { RestaurantContext } from '../../context';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    background: 'rgba(38, 207, 175, 0.75)',
    paddingTop: '6%',
  },
  form: {
    margin: 'auto',
    maxWidth: 300,
  },
  continue: {
    borderRadius: 20,
    fontWeight: 500,
    fontSize: 12,
    padding: theme.spacing(1, 6),
  },
  title: {
    fontWeight: 500,
    fontSize: 48,
    lineHeight: '56px',
    color: '#fff',
    marginBottom: theme.spacing(4),
  },
}));

export default () => {
  const classes = useStyles();
  const { loading, createRestaurant } = useContext(RestaurantContext);

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          restaurantName: '',
          locationName: '',
          tables: '',
        }}
        validate={(values) => {
          const errors = {};

          if (!values.restaurantName) {
            errors.restaurantName = 'Restaurant name is required';
          }

          if (!values.locationName) {
            errors.locationName = 'Location name is required';
          }

          if (!values.tables) {
            errors.tables = '# of tables is required';
          }

          return errors;
        }}
        onSubmit={({ restaurantName, locationName, tables }) => {
          createRestaurant({
            name: restaurantName,
            locationName,
            locationTables: tables,
          });
        }}
      >
        {({ submitForm }) => (
          <Form className={classes.form}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography className={classes.title} variant="h4">
                  Let’s set up your first location
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  component={TextField}
                  type="text"
                  label="Restaurant Name"
                  placeholder="ie Applebee’s"
                  name="restaurantName"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  component={TextField}
                  type="text"
                  label="First Location Name"
                  placeholder="ie Downtown"
                  name="locationName"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  component={TextField}
                  type="number"
                  label="# of Tables (Best guess)"
                  placeholder="ie 22"
                  name="tables"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {loading && <LinearProgress />}
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.continue}
                  size="large"
                  disableElevation
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  endIcon={<ChevronRightIcon />}
                  onClick={submitForm}
                >
                  CONTINUE
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';

import LeftHand from './LeftHand';
import RightHand from './RightHand';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 60,
    boxShadow: '0px 4px 10px -5px rgba(0, 0, 0, 0.15)',
  },
  saveButton: {
    marginRight: theme.spacing(2),
    marginLeft: 'auto',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  container: {
    marginTop: theme.spacing(4),
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  return (
    <div>
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <LeftHand />
          </Grid>
          <Grid item xs={4}>
            <RightHand />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Dashboard;

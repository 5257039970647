import { useSnackbar } from 'notistack';

export default () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleNotify = (message, variant = 'error') => {
    enqueueSnackbar(message, { variant });
  };

  return {
    notify: handleNotify,
  };
};

import gql from 'graphql-tag';

export const SIGNUP = gql`
  mutation signUp($email: String!, $password: String!, $passwordConfirmation: String!) {
    signUp(
      input: {
        email: $email
        password: $password
        passwordConfirmation: $passwordConfirmation
      }
    ) {
      clientMutationId
      user {
        id
        email
        token
        restaurant {
          id
          name
          domain
          logoUrl
          locations {
            id
            name
            tables
            position
            menus {
              id
              name
              pdfUrl
              position
            }
          }
          menus {
            id
            name
            pdfUrl
            position
          }
        }
      }
      errors {
        message
        path
      }
    }
  }
`;

export const LOGIN = gql`
  mutation login($email: String!, $password: String!, $clientMutationId: String) {
    login(
      input: { email: $email, password: $password, clientMutationId: $clientMutationId }
    ) {
      clientMutationId
      user {
        id
        email
        token
        restaurant {
          id
          name
          domain
          logoUrl
          locations {
            id
            name
            tables
            position
            menus {
              id
              name
              pdfUrl
              position
            }
          }
          menus {
            id
            name
            pdfUrl
            position
          }
        }
      }
    }
  }
`;

export const CREATE_LOCATION = gql`
  mutation createLocation(
    $address: String
    $clientMutationId: String
    $name: String!
    $tables: Int!
  ) {
    createLocation(
      input: {
        address: $address
        clientMutationId: $clientMutationId
        name: $name
        tables: $tables
      }
    ) {
      clientMutationId
      location {
        id
        name
        address
        position
        tables
        token
        menus {
          id
          name
          pdfUrl
          position
        }
      }
    }
  }
`;

export const CREATE_MENU = gql`
  mutation createMenu(
    $clientMutationId: String
    $locationId: ID
    $name: String!
    $pdf: Upload
  ) {
    createMenu(
      input: {
        clientMutationId: $clientMutationId
        locationId: $locationId
        name: $name
        pdf: $pdf
      }
    ) {
      clientMutationId
      menu {
        id
        name
        pdfUrl
        position
      }
    }
  }
`;

export const CREATE_RESTAURANT = gql`
  mutation createRestaurant(
    $clientMutationId: String
    $name: String!
    $domain: String
    $locationName: String
    $locationTables: Int
    $locationAddress: String
  ) {
    createRestaurant(
      input: {
        clientMutationId: $clientMutationId
        name: $name
        domain: $domain
        locationName: $locationName
        locationTables: $locationTables
        locationAddress: $locationAddress
      }
    ) {
      clientMutationId
      restaurant {
        id
        name
        menus {
          id
          name
          pdfUrl
          position
        }
        locations {
          id
          name
          position
          tables
          menus {
            id
            name
            pdfUrl
            position
          }
        }
      }
      errors {
        message
        path
      }
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation deleteLocation($clientMutationId: String, $id: ID!) {
    deleteLocation(input: { clientMutationId: $clientMutationId, id: $id }) {
      clientMutationId
      id
      errors {
        message
        path
      }
    }
  }
`;

export const DELETE_MENU = gql`
  mutation deleteMenu($clientMutationId: String, $id: ID!) {
    deleteMenu(input: { clientMutationId: $clientMutationId, id: $id }) {
      clientMutationId
      id
      errors {
        message
        path
      }
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation updateLocation(
    $address: String
    $clientMutationId: String
    $id: ID!
    $name: String
    $position: Int
    $tables: Int
  ) {
    updateLocation(
      input: {
        address: $address
        clientMutationId: $clientMutationId
        id: $id
        name: $name
        position: $position
        tables: $tables
      }
    ) {
      clientMutationId
      location {
        id
        name
        tables
        position
        menus {
          id
          name
          pdfUrl
          position
        }
      }
      errors {
        message
        path
      }
    }
  }
`;

export const UPDATE_MENU = gql`
  mutation updateMenu(
    $clientMutationId: String
    $id: ID!
    $name: String
    $position: Int
    $pdf: Upload
  ) {
    updateMenu(
      input: {
        clientMutationId: $clientMutationId
        id: $id
        name: $name
        position: $position
        pdf: $pdf
      }
    ) {
      clientMutationId
      errors {
        message
        path
      }
      menu {
        id
        name
        position
        pdfUrl
      }
    }
  }
`;

export const UPDATE_RESTAURANT = gql`
  mutation updateRestaurant(
    $clientMutationId: String
    $domain: String
    $id: ID!
    $logo: Upload
    $name: String
    $resize: String
  ) {
    updateRestaurant(
      input: {
        clientMutationId: $clientMutationId
        domain: $domain
        id: $id
        logo: $logo
        name: $name
      }
    ) {
      clientMutationId
      restaurant {
        id
        name
        logoUrl(resize: $resize)
        menus {
          id
          name
          position
          pdfUrl
        }
        locations {
          id
          name
          tables
          position
          menus {
            id
            name
            pdfUrl
            position
          }
        }
      }
      errors {
        message
        path
      }
    }
  }
`;

export const UPDATE_LOCATION_MENU = gql`
  mutation updateLocationMenu(
    $menuId: ID!
    $locationId: ID!
    $connect: Boolean!
    $clientMutationId: String
  ) {
    updateLocationMenu(
      input: {
        menuId: $menuId
        locationId: $locationId
        connect: $connect
        clientMutationId: $clientMutationId
      }
    ) {
      clientMutationId
      location {
        id
        name
        address
        position
        tables
        token
        menus {
          id
          name
          position
          pdfUrl
        }
      }
    }
  }
`;

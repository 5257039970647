import React, { useMemo, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';

import { useDropzone } from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 3,
  backgroundColor: '#F5F6F6',
  color: '#3C4858',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  width: 320,
  height: 110,
  fontWeight: 500,
  fontSize: 12,
  lineHeight: '14px',
  borderColor: 'transparent',
  borderStyle: 'dashed',
  borderWidth: 2,
  cursor: 'pointer',
  position: 'relative',
};

const activeStyle = {
  borderColor: '#26CFAF',
};

const acceptStyle = {
  borderColor: '#4CAF50',
};

const rejectStyle = {
  borderColor: '#F44336',
};

const textStyle = {
  border: '1px solid #3C4858',
  borderRadius: 3,
  padding: '13px 23px',
  margin: 0,
};

const useStyles = makeStyles((theme) => ({
  action: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editButton: {
    marginRight: theme.spacing(1),
  },
}));

export default ({ imageUrl, handleFile }) => {
  const classes = useStyles();

  const [isHover, setIsHover] = useState(false);
  const onDrop = ([file]) => {
    handleFile(file);
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'image/*', onDrop });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} – {file.size} bytes
    </li>
  ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragActive, isDragReject]
  );

  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleFile(null);
  };

  return (
    <section>
      <div
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        {...getRootProps({ style })}
      >
        <input {...getInputProps()} />
        {!imageUrl && <p style={textStyle}>DRAG AND DROP OR CLICK HERE</p>}
        {imageUrl && <img src={imageUrl} alt="" />}
        {isHover && imageUrl && (
          <div className={classes.action}>
            <IconButton color="primary" aria-label="edit" className={classes.editButton}>
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              color="secondary"
              aria-label="delete"
              className={classes.deleteButton}
              onClick={handleDelete}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        )}
      </div>
    </section>
  );
};

import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import AddLocation from './AddLocation';
import AddMenu from './AddMenu';
import UploadLogo from './UploadLogo';
import { LocationContext } from '../../context';

const useStyles = makeStyles((theme) => ({
  root: {},
  locations: {
    marginBottom: theme.spacing(5),
    '& > h6': {
      marginBottom: theme.spacing(1),
    },
  },
  addButton: {
    borderRadius: 20.5,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& > h6': {
      marginRight: theme.spacing(4),
    },
  },
}));

const LeftHand = () => {
  const classes = useStyles();
  const { selectedLocation } = useContext(LocationContext);

  return (
    <div className={classes.root}>
      <UploadLogo />
      <div className={classes.locations}>
        <Typography component="h6">Restaurant Locations</Typography>
        <AddLocation />
      </div>
      <div className={classes.menus}>
        <div className={classes.header}>
          <Typography component="h6">Restaurant Menus</Typography>
          <Typography component="span">{selectedLocation?.name}</Typography>
        </div>
        <AddMenu />
      </div>
    </div>
  );
};

export default LeftHand;
